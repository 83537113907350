import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { TestComponentBlock } from "../../lib/storyblok/types/blocks/TestComponentBlock";
import TestComponentUi from "../../ui/TestComponent";

const TestComponent: StoryblokBlockComponent<TestComponentBlock> = ({
  markdown1,
  markdown2,
  markdown3,
  markdown4,
  markdown5,
  markdown6,
  markdown7,
  markdown8,
  markdown9,
  markdown10,
}) => {
  return (
    <TestComponentUi
      markdown1={markdown1}
      markdown2={markdown2}
      markdown3={markdown3}
      markdown4={markdown4}
      markdown5={markdown5}
      markdown6={markdown6}
      markdown7={markdown7}
      markdown8={markdown8}
      markdown9={markdown9}
      markdown10={markdown10}
    />
  );
};

export default TestComponent;
