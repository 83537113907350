/* eslint-disable */
import React, { ReactNode, useEffect, useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import Image from "next/image";
import s from "./CTAForm.module.scss";
import bs from "../../styles/block.spacing.module.scss";

import c from "../../styles/container.module.scss";
import swooshDefaultTop from "../../public/images/divider-block-swoosh-default-top.png";
import swooshDefaultBottom from "../../public/images/divider-block-swoosh-default-bottom.png";
import swooshInvertedTop from "../../public/images/divider-block-swoosh-light-top.png";
import swooshInvertedBottom from "../../public/images/divider-block-swoosh-light-bottom.png";

import {
  StyleVariant,
  InnerSpacingVariant,
} from "../../lib/storyblok/types/shared";
import BlockBackground from "../Common/BlockBackground";
import BlockIntro from "../Common/BlockIntro";
import Loader from "../Common/Loader";

type Props = {
  title: string;
  text?: string;
  copy?: string;
  image?: ReactNode;
  formUrl: string;
  spacing: InnerSpacingVariant;
  style: StyleVariant;
  divider: "none" | "swooshBlueTop" | "swooshBlueBottom" | "swooshBlueBoth";
  inverted?: boolean;
};

const CTAForm = ({
  title,
  text,
  copy,
  image,
  spacing,
  style,
  divider,
  formUrl,
  inverted,
}: Props) => {
  const iframeId = "ctaFormIFrame";
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { ref: formRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: "300px",
  });

  const [height, setHeight] = useState(1000);
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");

  useEffect(() => {
    if (!formUrl) return;

    let originUrl = "";
    try {
      originUrl = new URL(formUrl).origin;
    } catch (error) {
      return;
    }

    const handleMessage = (message: MessageEvent) => {
      if (message.origin !== originUrl) return;

      // Check if it's a resize message
      const isResizeMessage =
        (message.data.type === "ctaFormResize-" + formUrl ||
          message.data.type === "pardotFormResize-" + formUrl) &&
        typeof message.data.height === "number";

      if (isResizeMessage) {
        setHeight(message.data.height + 2);
        setIsFormLoaded(true);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, [formUrl]);

  useEffect(() => {
    if (inView && formUrl) {
      setIframeUrl(formUrl);

      if (typeof window !== "undefined") {
        const timer = setTimeout(() => {
          const fullPath = window.location.pathname
            .toLowerCase()
            .replace(/^\//, "");

          const separator = formUrl.includes("?") ? "&" : "?";
          const urlWithParams = `${formUrl}${separator}converting_page=${encodeURIComponent(
            fullPath
          )}`;

          setIframeUrl(urlWithParams);
          //console.log("Updating iframe URL after delay:", urlWithParams);
        }, 2000);

        return () => clearTimeout(timer);
      }
    }
  }, [inView, formUrl]);

  const handleIframeLoad = () => {
    if (!isFormLoaded) {
      setHeight(1000);
    }
  };

  return (
    <section className={cn(s.ctaForm, bs[spacing], s[style])}>
      <BlockBackground style={style} opacity={100} />

      {divider === "swooshBlueTop" || divider === "swooshBlueBoth" ? (
        <div className={s.topImage}>
          <Image
            src={inverted ? swooshInvertedTop : swooshDefaultTop}
            alt="Swoosh Top"
            objectFit="contain"
            layout="intrinsic"
            quality="100"
          />
        </div>
      ) : null}

      <div className={cn(c.wide, s.wrapper)}>
        <div className={cn(s.content)}>
          <BlockIntro
            alignment={"alignLeft"}
            inverted={inverted}
            title={title}
            description={text}
            copy={copy}
            width={"full"}
            spaceHorizontal={false}
          />

          {image && <div className={cn(s.imageContainer)}>{image}</div>}
        </div>

        {formUrl && (
          <div className={cn(s.form)} ref={formRef}>
            <div>
              {inView ? (
                <iframe
                  title={formUrl}
                  ref={iframeRef}
                  id={iframeId}
                  width="100%"
                  height={height}
                  src={iframeUrl}
                  onLoad={handleIframeLoad}
                  style={{
                    opacity: isFormLoaded ? 1 : 0,
                    transition: "opacity 0.3s ease-in-out",
                  }}
                />
              ) : null}
              {(!inView || !isFormLoaded) && (
                <div className={cn(s.loadingContainer)}>
                  <Loader variant="default" size="medium" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {divider === "swooshBlueBottom" || divider === "swooshBlueBoth" ? (
        <div className={s.bottomImage}>
          <Image
            src={inverted ? swooshInvertedBottom : swooshDefaultBottom}
            alt="Swoosh Bottom"
            objectFit="contain"
            layout="intrinsic"
            quality="100"
          />
        </div>
      ) : null}
    </section>
  );
};

export default CTAForm;
