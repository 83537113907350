import React from "react";
import s from "./TestComponent.module.scss";
import BlockIntro from "../Common/BlockIntro";

const TestComponent = ({
  markdown1,
  markdown2,
  markdown3,
  markdown4,
  markdown5,
  markdown6,
  markdown7,
  markdown8,
  markdown9,
  markdown10,
}) => {
  return (
    <>
      <section className={s.section}>
        <h1>Markdown 1</h1>
        <BlockIntro
          description={markdown1}
          alignment={"alignLeft"}
          inverted={false}
          width={"contained"}
        />
      </section>
      <hr />
      <section className={s.section}>
        <h1>Markdown 2</h1>
        <BlockIntro
          description={markdown2}
          alignment={"alignLeft"}
          inverted={false}
          width={"contained"}
        />
      </section>
      <hr />
      <section className={s.section}>
        <h1>Markdown 3</h1>
        <BlockIntro
          description={markdown3}
          alignment={"alignLeft"}
          inverted={false}
          width={"contained"}
        />
      </section>
      <hr />
      <section className={s.section}>
        <h1>Markdown 4</h1>
        <BlockIntro
          description={markdown4}
          alignment={"alignLeft"}
          inverted={false}
          width={"contained"}
        />
      </section>
      <hr />
      <section className={s.section}>
        <h1>Markdown 5</h1>
        <BlockIntro
          description={markdown5}
          alignment={"alignLeft"}
          inverted={false}
          width={"contained"}
        />
      </section>
      <hr />
      <section className={s.section}>
        <h1>Markdown 6</h1>
        <BlockIntro
          description={markdown6}
          alignment={"alignLeft"}
          inverted={false}
          width={"contained"}
        />
      </section>
      <hr />
      <section className={s.section}>
        <h1>Markdown 7</h1>
        <BlockIntro
          description={markdown7}
          alignment={"alignLeft"}
          inverted={false}
          width={"contained"}
        />
      </section>
      <hr />
      <section className={s.section}>
        <h1>Markdown 8</h1>
        <BlockIntro
          description={markdown8}
          alignment={"alignLeft"}
          inverted={false}
          width={"contained"}
        />
      </section>
      <hr />
      <section className={s.section}>
        <h1>Markdown 9</h1>
        <BlockIntro
          description={markdown9}
          alignment={"alignLeft"}
          inverted={false}
          width={"contained"}
        />
      </section>
      <hr />
      <section className={s.section}>
        <h1>Markdown 10</h1>
        <BlockIntro
          description={markdown10}
          alignment={"alignLeft"}
          inverted={false}
          width={"contained"}
        />
      </section>
    </>
  );
};

export default TestComponent;
