/* eslint-disable */
import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import CtaForm from "../../ui/CTAForm";
import { CtaFormBlock } from "../../lib/storyblok/types/blocks/CtaFormBlock";
import { imageSizes, isValidImageData } from "../../lib/utils/imageUtils";
import ImageVaultImage from "../ImageVaultImage";
import { useContentStyle } from "../../hooks/useStyle";

const CTAForm: StoryblokBlockComponent<CtaFormBlock> = ({
  text,
  title,
  copy,
  image,
  formUrl,
  spacing,
  style,
  divider,
}) => {
  const contentStyle = useContentStyle(style);
  const isInverted = style === "" || style === undefined ? false : contentStyle;
  const sanitizedFormUrl = formUrl ? formUrl.trim() : ""; // Ensure formUrl has no leading/trailing spaces

  return (
    <CtaForm
      title={title}
      text={text}
      copy={copy}
      image={
        image && isValidImageData(image) ? (
          <ImageVaultImage sizes={imageSizes("720px")} image={image} contain />
        ) : null
      }
      formUrl={sanitizedFormUrl}
      spacing={spacing}
      style={style}
      divider={divider}
      inverted={isInverted}
    />
  );
};

export default CTAForm;
