import { useCallback, useEffect, useRef, useState } from "react";
import { DownloadsGatedDownloadItemBlock } from "../../lib/storyblok/types/blocks/DownloadsGatedDownloadItem";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Downloads from "../../ui/Downloads/Downloads";

const { GatedDownload } = Downloads;

const DownloadsGatedDownloadItem: StoryblokBlockComponent<
  DownloadsGatedDownloadItemBlock
> = ({ _uid, icon, title, subtitle, form }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [height, setHeight] = useState(800);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const formUrl =
    form && form[0] && form[0].formUrl ? form[0].formUrl.trim() : "";
  const formUrlWithId = formUrl ? `${formUrl}#${_uid}` : "";

  const onMessage = useCallback(
    (message) => {
      if (
        formUrl &&
        ((message.data.type === "pardotFormResize-" + formUrlWithId &&
          message.data.height) ||
          (message.data.type === "ctaFormResize-" + formUrlWithId &&
            message.data.height))
      ) {
        setTimeout(() => {
          setHeight(message.data.height + 2);
        }, 0);
      }

      if (message.data.type === "pardotFormSubmit") {
        modalRef.current &&
          modalRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
      }
    },
    [formUrl, formUrlWithId]
  );

  useEffect(() => {
    const messageHandler = (message) => onMessage(message);
    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [onMessage]);

  return (
    <GatedDownload
      modalRef={modalRef}
      toggleModal={() => setIsModalOpen(!isModalOpen)}
      isModalOpen={isModalOpen}
      icon={icon}
      title={title}
      subtitle={subtitle}
      formTitle={form && form[0] ? form[0].title : ""}
      formText={form && form[0] ? form[0].text : ""}
      form={
        form[0] &&
        formUrl && (
          <iframe
            id={_uid}
            ref={iframeRef}
            title={form[0].title || "Form"}
            width="100%"
            height={height}
            src={formUrlWithId}
          />
        )
      }
    />
  );
};

export default DownloadsGatedDownloadItem;
